import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Page, View, Text } from 'lib'
import { CircularProgress } from '@material-ui/core'

const Start = () => {
  const styles = useStyles()
  const [longLoad, setLongLoad] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLongLoad(true)
    }, 15000)
  }, [])

  return (
    <Page center emptyNavbar>
      <>
        <View style={styles.wrapper}>

          <View style={styles.circularWrapper}>
            <CircularProgress size={20}/>
          </View>
          {longLoad && <Text text={'Cannot connect to the server. Please make sure your internet is working and try again in a few minutes.'}/>}
        </View>
      </>
    </Page>
  )
}

const useStyles = makeStyles((theme) => ({
  circularWrapper: {
    marginTop: '50px',
    marginBottom: '20px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginLeft: '20px',
    marginRight: '20px',
  },
}))

export default Start
